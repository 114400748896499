*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: brandon-grotesque, sans-serif;
}

.main.wrapper {
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.main.wrapper:after {
  content: " ";
  clear: both;
  display: block;
}

@media (max-width: 1000px) {
  .main.wrapper {
    max-width: 90%;
  }
}

.main-container {
  text-align: center;
}

body.base-pink {
  color: #d9d9d9;
  background-color: #333;
  transition: all .3s;
}

body.base-pink a {
  color: #ff5773;
}

body.base-pink a span:before {
  color: #d9d9d9;
}

body.base-pink a.new-link {
  color: #333;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.base-pink a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.base-green {
  color: #d9d9d9;
  background-color: #333;
  transition: all .3s;
}

body.base-green a {
  color: #7df67f;
}

body.base-green a span:before {
  color: #d9d9d9;
}

body.base-green a.new-link {
  color: #333;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.base-green a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.base-blue {
  color: #d9d9d9;
  background-color: #333;
  transition: all .3s;
}

body.base-blue a {
  color: #0275d9;
}

body.base-blue a span:before {
  color: #d9d9d9;
}

body.base-blue a.new-link {
  color: #333;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.base-blue a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.pink {
  color: #ff5773;
  background-color: #333;
  transition: all .3s;
}

body.pink a {
  color: #d9d9d9;
}

body.pink a span:before {
  color: #ff5773;
}

body.pink a.new-link {
  color: #333;
  background-color: #ff5773;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.pink a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.blue {
  color: #0275d9;
  background-color: #333;
  transition: all .3s;
}

body.blue a {
  color: #d9d9d9;
}

body.blue a span:before {
  color: #0275d9;
}

body.blue a.new-link {
  color: #333;
  background-color: #0275d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.blue a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.green {
  color: #7df67f;
  background-color: #333;
  transition: all .3s;
}

body.green a {
  color: #d9d9d9;
}

body.green a span:before {
  color: #7df67f;
}

body.green a.new-link {
  color: #333;
  background-color: #7df67f;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.green a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.light-pink {
  color: #333;
  background-color: #d9d9d9;
  transition: all .3s;
}

body.light-pink a {
  color: #ff5773;
}

body.light-pink a span:before {
  color: #333;
}

body.light-pink a.new-link {
  color: #d9d9d9;
  background-color: #333;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.light-pink a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.light-blue {
  color: #333;
  background-color: #d9d9d9;
  transition: all .3s;
}

body.light-blue a {
  color: #0275d9;
}

body.light-blue a span:before {
  color: #333;
}

body.light-blue a.new-link {
  color: #d9d9d9;
  background-color: #333;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.light-blue a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.light-pink-blue {
  color: #ff5773;
  background-color: #d9d9d9;
  transition: all .3s;
}

body.light-pink-blue a {
  color: #0275d9;
}

body.light-pink-blue a span:before {
  color: #ff5773;
}

body.light-pink-blue a.new-link {
  color: #d9d9d9;
  background-color: #ff5773;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.light-pink-blue a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.light-blue-pink {
  color: #0275d9;
  background-color: #d9d9d9;
  transition: all .3s;
}

body.light-blue-pink a {
  color: #ff5773;
}

body.light-blue-pink a span:before {
  color: #0275d9;
}

body.light-blue-pink a.new-link {
  color: #d9d9d9;
  background-color: #0275d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.light-blue-pink a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.dark-pink-blue {
  color: #ff5773;
  background-color: #333;
  transition: all .3s;
}

body.dark-pink-blue a {
  color: #0275d9;
}

body.dark-pink-blue a span:before {
  color: #ff5773;
}

body.dark-pink-blue a.new-link {
  color: #333;
  background-color: #ff5773;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.dark-pink-blue a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.dark-blue-pink {
  color: #0275d9;
  background-color: #333;
  transition: all .3s;
}

body.dark-blue-pink a {
  color: #ff5773;
}

body.dark-blue-pink a span:before {
  color: #0275d9;
}

body.dark-blue-pink a.new-link {
  color: #333;
  background-color: #0275d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.dark-blue-pink a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.dark-blue-green {
  color: #0275d9;
  background-color: #333;
  transition: all .3s;
}

body.dark-blue-green a {
  color: #7df67f;
}

body.dark-blue-green a span:before {
  color: #0275d9;
}

body.dark-blue-green a.new-link {
  color: #333;
  background-color: #0275d9;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.dark-blue-green a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.dark-pink-green {
  color: #ff5773;
  background-color: #333;
  transition: all .3s;
}

body.dark-pink-green a {
  color: #7df67f;
}

body.dark-pink-green a span:before {
  color: #ff5773;
}

body.dark-pink-green a.new-link {
  color: #333;
  background-color: #ff5773;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.dark-pink-green a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.dark-green-blue {
  color: #7df67f;
  background-color: #333;
  transition: all .3s;
}

body.dark-green-blue a {
  color: #0275d9;
}

body.dark-green-blue a span:before {
  color: #7df67f;
}

body.dark-green-blue a.new-link {
  color: #333;
  background-color: #7df67f;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.dark-green-blue a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

body.dark-green-pink {
  color: #7df67f;
  background-color: #333;
  transition: all .3s;
}

body.dark-green-pink a {
  color: #ff5773;
}

body.dark-green-pink a span:before {
  color: #7df67f;
}

body.dark-green-pink a.new-link {
  color: #333;
  background-color: #7df67f;
  border-radius: 5px;
  height: 100%;
  margin-left: 5px;
  padding: 10px 12px;
  font-size: 18px;
  font-weight: bold;
}

@media (max-width: 605px) {
  body.dark-green-pink a.new-link {
    margin-bottom: -2px;
    padding: 4px 6px;
    font-size: 10px;
  }
}

ul {
  text-align: left;
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

ul li {
  display: block;
}

@media (max-width: 605px) {
  ul li {
    float: left;
    margin-right: 20px;
  }
}

ul li a {
  display: inline-block;
}

a {
  text-transform: uppercase;
  height: 1.25em;
  font-size: 40px;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (max-width: 605px) {
  a {
    font-size: 18px;
  }
}

a span {
  font-weight: bold;
  transition: transform .2s;
  display: inline-block;
  position: relative;
}

a span:before {
  text-wrap: nowrap;
  content: attr(data-hover);
  font-weight: normal;
  position: absolute;
  top: 100%;
  transform: translate3d(0, 0, 0);
}

a:hover span {
  transform: translateY(-100%);
}

#logo {
  max-width: 100%;
}

#logo .logo-section {
  fill: currentColor;
}

/*# sourceMappingURL=index.28d10b0f.css.map */
