@mixin body-colors($back-color, $primary-color, $secondary-color) {
  color: $primary-color;
  background-color: $back-color;
  transition: all 0.3s;
  a {
    color: $secondary-color;

    span:before {
      color: $primary-color;
    }
  }
  a.new-link {
    background-color: $primary-color;
    color: $back-color;
    margin-left: 5px;
    padding: 10px 12px;
    border-radius: 5px;
    font-size: 18px;
    height: 100%;
    font-weight: bold;
    @media (max-width: 605px) {
      font-size: 10px;
      padding: 4px 6px;
      margin-bottom: -2px;
    }
  }
}

@mixin clearfix() {
  &:after {
    content:" ";
    display:block;
    clear:both;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

$darkest-gray: #333;
$lightest-gray: #d9d9d9;
$pink: #ff5773;
$green: #7df67f;
$blue: #0275d9;

body {
  font-family: "brandon-grotesque",sans-serif;
}

.main.wrapper {
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 50px;

  @include clearfix();
  @media (max-width: 1000px) {
    max-width: 90%;
  }
}

.main-container {
  text-align: center;
}

body.base-pink {
  @include body-colors($darkest-gray, $lightest-gray, $pink);
}

body.base-green {
  @include body-colors($darkest-gray, $lightest-gray, $green);
}

body.base-blue {
  @include body-colors($darkest-gray, $lightest-gray, $blue);
}

body.pink {
  @include body-colors($darkest-gray, $pink, $lightest-gray);
}

body.blue {
  @include body-colors($darkest-gray, $blue, $lightest-gray);
}

body.green {
  @include body-colors($darkest-gray, $green, $lightest-gray);
}

body.light-pink {
  @include body-colors($lightest-gray, $darkest-gray, $pink);
}

body.light-blue {
  @include body-colors($lightest-gray, $darkest-gray, $blue);
}

body.light-pink-blue {
  @include body-colors($lightest-gray, $pink, $blue);
}

body.light-blue-pink {
  @include body-colors($lightest-gray, $blue, $pink);
}

body.dark-pink-blue {
  @include body-colors($darkest-gray, $pink, $blue);
}

body.dark-blue-pink {
  @include body-colors($darkest-gray, $blue, $pink);
}

body.dark-blue-green {
  @include body-colors($darkest-gray, $blue, $green);
}

body.dark-pink-green {
  @include body-colors($darkest-gray, $pink, $green);
}

body.dark-green-blue {
  @include body-colors($darkest-gray, $green, $blue);
}

body.dark-green-pink {
  @include body-colors($darkest-gray, $green, $pink);
}

ul {
  margin: 0 auto;
  list-style-type: none;
  text-align: left;
  padding: 0;
  max-width: 90%;

  li {
    display: block;

    @media (max-width: 605px) {
      float: left;
      margin-right: 20px;
    }
    a {
      display: inline-block;
    }
  }
}

a {
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  font-size: 40px;
  height: 1.25em;
  overflow: hidden;
  position: relative;

  @media (max-width: 605px) {
    font-size: 18px;
  }

  span {
    font-weight: bold;
    display: inline-block;
    position: relative;
    transition: transform 0.2s;

    &:before {
      font-weight: normal;
      text-wrap: nowrap;
      position: absolute;
      top: 100%;
      content: attr(data-hover);
      transform: translate3d(0, 0, 0);
    }
  }

  &:hover {
    span {
      transform: translateY(-100%);
    }
  }
}

#logo {
  max-width: 100%;
  .logo-section {
    fill: currentColor;
  }
}
